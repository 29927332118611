
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import React, { useEffect } from 'react'
import { useTranslation } from '../../services/translation'
import { Container, Row, Col } from 'react-bootstrap'
import { useRouter } from 'next/router'
import Layout from '../../components/Layout'
import { useAuth } from '../../contexts/auth'
import LoginForm from '../../components/LoginForm'
import { useNotification } from '../../contexts/notification'

const Login = () => {
  const { signOut, loadingUser } = useAuth()
  useEffect(() => {
    if (!loadingUser) {
      signOut()
    }
  }, [loadingUser])
  const { t } = useTranslation()
  const router = useRouter()
  const url = router.query.url
  const { getUserPermission } = useNotification()
  const onLoginSuccess = async (socialLogin, result) => {
    let urlRedirect
    if (socialLogin) {
      urlRedirect = result.loginSocial.firstLogin
        ? `/successfullyregistration?type=account${url ? `&url=${url}` : ''}`
        : url || '/account'
    } else {
      urlRedirect = result.login.user.emailConfirmed
        ? url || '/account'
        : '/emailconfirm'
    }
    router.push(urlRedirect)
    await getUserPermission()
  }
  return (
    <Layout>
      <div className='b-inter'>
        <h1>{t('login.tit')}</h1>
      </div>
      <Container className='box-login'>
        <Row>
          <Col xs={12} sm={6} md={6} lg={7}>
            <h3>{t('login.txt')}</h3>
            <img
              src='/assets/img/crowdfunding.svg'
              className='crowdfunding-svg'
              alt='Crowdfunding Co3D'
            />
          </Col>
          <Col xs={12} sm={6} md={6} lg={5}>
            <LoginForm onSuccess={onLoginSuccess} />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

const __Page_Next_Translate__ = Login


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      
    });
  